footer{
  color: #D5D5D5;
}
footer a {
  color: #D5D5D5;
}

footer a:hover{
  color: white;
}

footer p {
	margin-bottom: 0;
}

.footer-pad {
	padding-bottom:1em;
}

.footer {
background-color : #333333;
}

.footer-top{
  background: #2d4958;  
}

.footer-middle{
   background: #333333;
  padding: 1.5em 3em 0;
  color: white;
}

.footer-bottom{
  padding: 0.5em 0em;
  background-color: #F8F8F8;
  border-top: 1px solid #EAEAEA;
}

footer .copyright {
	
color: #666666;
float: left;
font-size: 12px;
}

.footer .widget ul li {
    border-bottom: 1px solid #444444;
	list-style: none;
	line-height: 1.6em;
}


.footer .widget-title {
    font-size: 1.3em;
    margin-bottom: 1em;
}

.footer .widget-title span {
    border-bottom: 2px solid #0FBE7C;
    padding-bottom: 5px;
}

.footer .textwidget.custom-html-widget {
	background:inherit;
	border:inherit;
	
}
	
	
.footer .textwidget.custom-html-widget a {
	color:#FFF;
	
}

.cc {
    font-size: 0.7em;
	text-align:right;
	padding-top:15px;
}



.footer-pad a:hover {
    color: #0FBE7C !important;
}

.footer-bottom a::after {
    content: ".";
    font-size: 23px;
    color: #0CB9E7;
    position: relative;
    bottom: 2px;
    margin: 0 6px;
}