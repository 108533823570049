#carousel-accueil {
	border-bottom: 1px solid #EAEAEA;
}
#carousel-accueil,
#carousel-accueil .slider-cycle {
	position: relative;
}
.wide-978 #carousel-accueil .slider-cycle,
.wide-1218 #carousel-accueil .slider-cycle {
   max-width: 1600px;
   margin: 0 auto;
}
#carousel-accueil .displayblock {
	display: block;
}
#carousel-accueil .displaynone {
	display: none;
}
.ie8 #carousel-accueil .entry-container {
	top: 25%;
}
#carousel-accueil .entry-container {
	top: 50%;
	-ms-transform: translateY(-50%); /* IE 9 */
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%);
	position: absolute;
	left: 5%;
	max-width: 40%;
}
.wide-978 #carousel-accueil .entry-container {
   left: 13.8%;
}
.wide-1218 #carousel-accueil .entry-container {
   left: 4.9%;
}
#carousel-accueil .slider-description {
	background-color: #555555;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 10px 15px;
	padding: 1rem 1.5rem;
}
#carousel-accueil img {
	margin-bottom: 0px;
}
#carousel-accueil .slider-title-head {
	display: inline-block;
	left: 0;
	margin-bottom: 5px;
	margin-bottom: 0.5rem;
}
#carousel-accueil .entry-title {
	padding: 0;
}
.slider-title-head .entry-title a {
	color: #FFFFFF;
	text-decoration: none;
}
#carousel-accueil .entry-title span {
	color: #FFFFFF;
	display: block;
	font-size: 26px;
}
#carousel-accueil .entry-content {
	color: #FFFFFF;
	padding-top: 5px;
	padding-top: 0.5rem;
}
#carousel-accueil .entry-content p {
	margin: 0;
	padding: 0;
}
#carousel-accueil .slider-read-more-button {
	background-color: #0FBE7C;
	color: #FFFFFF;
	text-align: center;
	font-family: 'Lato',sans-serif;
	padding: 10px 15px;
	padding: 1rem 1.5rem;
	float: left;
	margin-top: 15px;
	font-size: 20px;
}
#carousel-accueil .slider-read-more-button:hover {
	background-color: #666666;
}

/* Carousel captions */
.carousel-caption {
    color: #fff;
    text-align: left;

    text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.el {

	right: 55% !important;
	left: 5% !important;
}

/* Carousel indicators */

#carousel-accueil .carousel-indicators > li{
    margin: 0 2px;
    background-color: #f39c12;
    border-color: #f39c12;
    opacity: .7;
}
#carousel-accueil .carousel-indicators > li.active{
    width: 10px;
    height: 10px;
    opacity: 1;
}

.carousel-indicators li {
	position: relative;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	max-width: 30px;
	height: 3px;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.5);
}
