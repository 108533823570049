// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

.vc_cta3-container {

    margin-bottom: 0px !important;
    margin-left: auto;
    margin-right: auto;

}
