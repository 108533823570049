/* Mega menu canvas */
.dropdown--canvas {

    // Reset margin and padding
    .dropdown-menu {
        left    : auto;
        padding : 0;
        margin  : 0;
    }

    // Default canvas width
    .dropdown-menu {
        width : 600px;
    }
}

.dropdown-menu {
	text-transform:none;
}


.dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #23a18c;
}

/* Align the menu to the left */
.dropdown--canvas--left .dropdown-menu {
    right : 0;
}

/* Set a smaller canvas size */
.dropdown--canvas--sm .dropdown-menu {
    width : 300px;
}


/* Barre de navigation */
.navbar {
    border-radius: 0;
}

.nav {
	padding-top:20px;
	font-size: 1em;
	font-family: Oswald;
	text-transform: uppercase;
}

.navbar-nav .nav-item + .nav-item, .navbar-nav .nav-link + .nav-link {
    margin-left: 1.3rem;
}

.bg-faded {
	background-color:#FFF;
}

.sticky.is-sticky {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.navbar-fixed-bottom, .navbar-fixed-top {
	border-radius: 0;
	background-color: #FFF !important;
	padding-left:21%;
	padding-right:21%;
}

@include media-breakpoint-up(sm) {
  .navbar-fixed-top {
      padding-left:6%;
	padding-right:6%;
  }
}

@include media-breakpoint-up(md) {
  .navbar-fixed-top {
      padding-left:6%;
	padding-right:6%;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-fixed-top {
      padding-left:8%;
	padding-right:8%;
  }
}


@include media-breakpoint-up(xl) {
  .navbar-fixed-top {
      padding-left:21%;
	padding-right:21%;
  }
}


.navbar-brand {
	max-height: 80px !important;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .active > .nav-link:focus, .navbar-light .navbar-nav .active > .nav-link:hover, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.active:focus, .navbar-light .navbar-nav .nav-link.active:hover, .navbar-light .navbar-nav .nav-link.open, .navbar-light .navbar-nav .nav-link.open:focus, .navbar-light .navbar-nav .nav-link.open:hover, .navbar-light .navbar-nav .open > .nav-link, .navbar-light .navbar-nav .open > .nav-link:focus, .navbar-light .navbar-nav .open > .nav-link:hover {
	color: #0FBE7C;
}



/*Breadcrumb*/
.header-post-title-container {
	background-color:#00a18a;
    border-bottom: 1px solid #EAEAEA;
    padding: 6px 0;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05) inset;
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 15px;
	margin-top: 15px;
}

.header-post-title-container .post-title-wrapper {
	max-width: 100%;
	padding-left: 30px;
}

.header-post-title-class {
	color:#FFF;
	text-align:center;
	font-size: 22px;
	padding-bottom: 0;
	line-height: 36px;
}










