
.carousel-read-more-button {
	background-color: #0FBE7C;
	color: #FFFFFF;
	text-align: center;
	font-family: 'Lato',sans-serif;
	padding: 10px 15px;
	padding: 1rem 1.5rem;
	float: left;
	margin-top: 15px;
	font-size: 20px;
}

.vc_btn3.vc_btn3-color-default.vc_btn3-style-modern {
	float: right;
	background-color: #0FBE7C;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	color: #FFFFFF;
	font-size: 22px;
	line-height: 40px;
	text-align: center;
	font-family: 'Lato',sans-serif;
	padding: 5px 5%;
	margin-top: 4px;
}


.vc_cta3-container {

    margin-bottom: 0px !important;
    margin-left: auto;
    margin-right: auto;
}

.vc_btn3-container.vc_btn3-center .vc_btn3-block.vc_btn3-size-lg {
    font-size: 22px;
    padding: 18px 25px;
}


th, td {
	padding: 6px 10px;
}

.alert-warning {
    background-color: $esprit-libre;
    color: #FFF;
	margin-top:20px;
	border-color:none;
}

.search-form { 
margin-bottom:20px;
}

/* Bouton newsletter */

.btn.sml_submitbtn {
	float:right;
}

.sml_submitbtn {
   color: #FFFFFF;
	display:inline-block;
   margin-bottom: 30px;
   padding: 5px 25px;
   -moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
	cursor: pointer;
	background-color: #434a54;
	border: 0px;
   -webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
   font-size: 16px;
}
.sml_submitbtn:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
button:hover {
	-webkit-box-shadow:0 0 2px rgba(0, 0, 0, 0.6);
	-moz-box-shadow:0 0 2px rgba(0, 0, 0, 0.6);
	box-shadow:0 0 2px rgba(0, 0, 0, 0.6);
}

.sml_emailinput { 
padding-left:20px;
padding-right:0px;
border: 1px solid #EAEAEA;
background-color: #F8F8F8;
}