.wpb_widgetised_column.wpb_content_element {
    padding-left: 0.5em;
    padding-right: 0.2em;
}

.wpb_widgetised_column.wpb_content_element h3 {
	color:#383839;
    font-size: 1.1em;
	text-align:center;
}

.widget.search-2.widget_search {
    padding-bottom: 10px;
}


.widget.recent-posts-2.widget_recent_entries {
padding:.8em .8em 0.5em 0;
margin-bottom:0.6em;
background: #f0f3ed; /* Old browsers */
background: -moz-linear-gradient(top,  #fff 0%, #f0f3ed 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #fff 0%,#f0f3ed 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #fff 0%,#f0f3ed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f0f3ed',GradientType=0 ); /* IE6-9 */
border:1px solid rgba(0, 0, 0, .2);
}

.textwidget.custom-html-widget {
padding:.8em .8em .3em;
margin-bottom:0.6em;
background: #f0f3ed; /* Old browsers */
background: -moz-linear-gradient(top,  #fff 0%, #f0f3ed 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #fff 0%,#f0f3ed 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #fff 0%,#f0f3ed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f0f3ed',GradientType=0 ); /* IE6-9 */
border:1px solid rgba(0, 0, 0, .2);
}



.textwidget.custom-html-widget a {
    color: #383839;
    text-decoration: none;
}


.textwidget.custom-html-widget a:hover {
    color: #33b4a1;
    text-decoration: none;
}


.widget.recent-posts-2.widget_recent_entries a {
    color: #635e5e;
    text-decoration: none;
    font-size: .90em;
	font-style: oblique;
}

.widget.text-3.widget_text {

padding:.8em .8em .3em;
margin-bottom:0.6em;
background: #f0f3ed; /* Old browsers */
background: -moz-linear-gradient(top,  #eeeeee 0%, #f0f3ed 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #eeeeee 0%,#f0f3ed 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #eeeeee 0%,#f0f3ed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f0f3ed',GradientType=0 ); /* IE6-9 */

}

.widget.text-4.widget_text {

padding:.8em .8em .3em;
margin-bottom:0.6em;

background: #f0f3ed; /* Old browsers */
background: -moz-linear-gradient(top,  #eeeeee 0%, #f0f3ed 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #eeeeee 0%,#f0f3ed 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #eeeeee 0%,#f0f3ed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#f0f3ed',GradientType=0 ); /* IE6-9 */

}

.widget.sml_subscribe_widget.sml_subscribe_widget_display {
	padding:.8em .8em .3em;
margin-bottom:0.6em;

background: url("http://localhost/espritlibre/wp-content/uploads/vignette-actu.png") no-repeat; 
}

.prepend {
	text-align: left;
font-size: .8em;
max-width: 58%;
}

.search-submit {
    margin-top: 5px;
}

.comment-form input[type="email"]::-moz-placeholder, .comment-form input[type="text"]::-moz-placeholder, .comment-form input[type="url"]::-moz-placeholder, .comment-form textarea::-moz-placeholder, .form-control::-moz-placeholder, .search-form .search-field::-moz-placeholder {
    color: #23a18c;
    opacity: 1;
}



.widget.recent-posts-2.widget_recent_entries ul {
padding: 0;
margin: 0 0 0 1em;
}

.widget.recent-posts-2.widget_recent_entries li {
	list-style-type: none; 
    background: url("http://localhost/espritlibre/wp-content/themes/esprit-libre/assets/images/puce-esprit-libre.svg") no-repeat left;
	 background-position: 0 0.8em;
		padding-left: 2em;
		background-size:1.3em;
}

