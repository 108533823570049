body {
    padding-top: 30px;
    padding-bottom: 30px;
	background: #eaeaea;
}

#boxed {
	max-width: 1140px;
	margin: 0 auto;
	background-color: #FFF;
	box-shadow: 0 2px 6px rgba(100, 100, 100, 0.3);
	padding-left: 0px;
	padding-right: 0px;
}

.main {
font-family:Lato;
}

.main {
	position: relative;
	min-height: 1px;
	padding-right: 15px !important;
	padding-left: 15px !important; 
	width: 100%;
}

h1 {
font-size: 1.8rem;
font-weight: 600;
font-family:Oswald;
}

h2 {
font-size: 1.5rem;
font-weight: 600;
font-family:Oswald;
}

h3 {
color:#23a18c;
font-size: 1.2rem;
font-weight: 500;
font-family:Oswald;
}

h4 {
font-size: 1.1rem;
font-weight: 500;
font-family:Oswald;
}

hr {
border-top: 0.2em solid rgba(0,0,0,.1);
width: 60%;
}

.orange {
color: #eb5b40;
}

.vc_toggle_content li {

list-style-type: none;
background: url(http://localhost/espritlibre/wp-content/themes/esprit-libre/assets/images/puce-esprit-libre.svg) no-repeat 0;
    background-position-x: 0px;
    background-position-y: center;
    background-size: auto auto;
background-position: 0 .5em;
padding-left: 1.2em;
background-size: 0.8em;
}

/* Contact Form 7 personnalisation */
.wpcf7-form { 
	background: #F3F3F3;
	padding: 20px;
	margin-bottom: 30px;
	border-radius: 7px;
}
 
.wpcf7-form input { 
	background:#dff3ec; 
	padding:5px 7px; 
	border:1px solid #00a18a; 
	margin-bottom:8px; 
	border-radius:7px;
}
 
.wpcf7-form textarea { 
	padding:5px 0px 5px 7px; 
	background: #DFF3EC;
border: 2px dotted #0fbe7c;
border-radius: 7px;
	width:100%;
}
 
.wpcf7-form p { 
	color:#4f2a0f; 
	margin-top:10px; 
	margin-left:10px;
}
 
.wpcf7-form .wpcf7-submit { 
	background:#00a18a; 
	cursor:pointer; 
	padding-left:15px; 
	padding-right:15px; 
	color:#fff; 
	border-radius:7px;
	text-align:right;
}

.wpcf7-form .wpcf7-submit p { 
	text-align:right;
}

/* Création bouton animé */

.title {
  width: 200px;
  height: 20px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 4px;
  background: rgba(#000000, 0.2);
  padding: 15px 20px;
  transition: all .5s ease;
  border-radius: 3px;
  cursor: pointer;
  a {
    text-decoration: none;
    color: #FFF;
  }
}

.is-active {
  box-shadow: 0 20px 5px -10px rgba(#000, 0.4);
  transform: translateY(10px);
}

